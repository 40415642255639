import { environment } from '../../environments/environment';

let getApiURL = (_case?: string) => {
    let urls = {
        loginAS: "http://192.168.8.84:8525/api",
        brinsaApiJO: "http://192.168.8.155/BrinsaAPI-DEV-JO/api",
        // brinsaApiJO: "http://192.168.8.84:8529/api",
        dev1: "http://localhost:60913/api",
        dev2: "http://localhost:63244/api/bridge/dev",
        dev155: "http://192.168.8.155/BrinsaAPI-DEV/api",
        Prod155: "http://192.168.8.155/BrinsaAPI-prod/api",
        test1: "http://192.168.2.242:50245/api",
        test2: "http://192.168.2.242:8089/api",
        pilot: "http://192.168.8.84:8089/api",
        prod: "http://192.168.8.84:8088/api",
        dgt: "http://192.168.8.84:8090/api",
       // public_pilot: "http://transportes.brinsa.com.co/Proxy/api/bridge/pilot", // Piloto público
        public_pilot: "https://www.brinsadigital.com.co/api-dev", // Piloto público
        public_prod: "https://www.brinsadigital.com.co/api", // Piloto real
    };

    if (environment.production) {
        return environment['api_url'];
    }
    else if (_case) {
        return urls[_case];
    }
    else {
        return urls.test1;
    }
};

let getWebsiteURL = (_case?: string) => {
    let urls = {
        dev1: "http://localhost:4500",
        dev155: "https://www.brinsadigital.com.co",
        public_prod: "https://www.brinsadigital.com.co",
        public_pilot: "https://www.brinsadigital.com.co", 
    };
    return urls[_case];

    // if (environment.production) {
    //     return environment['website_url'];
    // }
    // else if (_case) {
    //     return urls[_case];
    // }
};

let getAsiVamosURL = (_case?: string) => {
    let urls = {
        // brinsaApiJO: "http://192.168.8.84:8529/api",
        // dev1: "http://transportes.brinsa.com.co/reportespdf/real", 
        // dev2: "http://transportes.brinsa.com.co/reportespdf/real",
        asiVamos_prod: "http://transportes.brinsa.com.co/reportespdf/real",
        asiVamos_pilot: "http://transportes.brinsa.com.co/reportespdf/piloto",
        // pilot: "http://192.168.8.84:8089",
        // prod: "http://192.168.8.84:8088",
        // dgt: "http://transportes.brinsa.com.co/reportespdf/real",
        // public_pilot: "https://www.brinsadigital.com.co", // Piloto público     
         public_prod: "https://www.brinsadigital.com.co",
    };

    if (environment.envName === 'public_prod') {
        return urls['public_prod'];
    }
    else if (_case) {
        return urls[_case];
    }
};

let getDashboarURL = (_case?: string) => {
    let urls = {
        dashboard_cli: "http://192.168.8.155:5200",
        //   dashboard_ventas: "http://svrapplinux.refisal.com.co:5020",
        dashboard_pro: "https://www.brinsadigital.com.co/brinsa-dashboard",
        dashboard_dev: "https://www.brinsadigital.com.co/brinsa-dashboard-dev",
        averias_dev:   "https://www.brinsadigital.com.co/brinsa-webaverias-dev",
        averias_prod:  "https://www.brinsadigital.com.co/brinsa-webaverias-prod",
        condComercial_dev:   "http://svrapplinux.refisal.com.co:4893/brinsa-condcomercial-dev",
        condComercial_prod:  "https://www.brinsadigital.com.co/brinsa-condcomercial-prod",
        muestras_dev:  "https://www.brinsadigital.com.co/brinsa-webmuestras-dev",
        muestras_prod: "https://www.brinsadigital.com.co/brinsa-webmuestras-prod",
        webcil_dev:    "https://www.brinsadigital.com.co/brinsa-wc-dev",
        webcil_prod:   "https://www.brinsadigital.com.co/brinsa-wc-prod",
        fininv_dev:    "https://www.brinsadigital.com.co/brinsa-webfininv-dev",
        fininv_prod:   "https://www.brinsadigital.com.co/brinsa-webfininv-prod",
        soldesc_dev:   "https://www.brinsadigital.com.co/brinsa-websoldesc-dev", 
        soldesc_prod:  "https://www.brinsadigital.com.co/brinsa-websoldesc-prod", 
        urlDX_dev:     "https://www.brinsadigital.com.co/api-datax-dev/microservice",
        urlDX_prod:    "https://www.brinsadigital.com.co/api-datax",
        urlAsiVamos_prod: "https://www.brinsadigital.com.co/brinsa-webasivamos/asi-vamos"
    };
    
    if (environment.envName === 'public_prod') {
      return urls['public_prod'];
    }
    else if (_case) {
      return urls[_case];
    }
      
  };

let getReportesURL = (_case?: string) => {
    let urls = {
        dev1: "http://transportes.brinsa.com.co/reportespdf/piloto", 
        dev2: "http://transportes.brinsa.com.co/reportespdf/piloto",
        test1: "http://transportes.brinsa.com.co/reportespdf/piloto",
        test2: "http://transportes.brinsa.com.co/reportespdf/piloto",
        pilot: "http://transportes.brinsa.com.co/reportespdf/piloto",
        prod: "http://transportes.brinsa.com.co/reportespdf/real",
        dgt: "http://transportes.brinsa.com.co/reportespdf/dgt",
        public_pilot: "http://transportes.brinsa.com.co/reportespdf/piloto",  
        public_prod: "http://transportes.brinsa.com.co/reportespdf/real",
    };
   
    if (environment.envName === 'public_prod') {
        return urls['public_prod'];
    }
    else if (environment.envName === 'dgt') {
        return urls['dgt'];
    }
    else if (_case) {
        return urls[_case];
    }
};

export const APP_CONFIG = {
    api_auth: getApiURL('public_prod'),
    api_url: getApiURL('public_prod'),
    website_url: getWebsiteURL('public_prod'),
    asiVamos_url: getAsiVamosURL('public_prod'),
    dashboar_url: getDashboarURL('dashboard_pro'),
    report_url: getReportesURL('public_prod'),
    averias_mfend: getDashboarURL('averias_prod'),   //Desarrollo Averias
    muestras_mfend: getDashboarURL('muestras_prod'), //Desarrollo Muestras
    webcilindros_mfend: getDashboarURL('webcil_prod'), //Desarrollo WebCilindros
    fininv_mfend: getDashboarURL('fininv_prod'), //Desarrollo finInv
    soldesc_mfend: getDashboarURL('soldesc_prod'), //Desarrollo solicitud descuento 
    url_Dx: getDashboarURL('urlDX_prod'),
    asiVamo_mfend: getDashboarURL('urlAsiVamos_prod'),
    condComercial_mfend: getDashboarURL('condComercial_prod') 
};


// export const APP_CONFIG = {
//     api_auth: getApiURL('Prod155'),
//     api_url: getApiURL('Prod155'),
//     website_url: getWebsiteURL('public_prod'),
//     asiVamos_url: getAsiVamosURL('public_prod'),
//     dashboar_url: getDashboarURL('dashboard_pro'),
//     report_url: getReportesURL('public_prod'),
//     averias_mfend: getDashboarURL('averias_prod'),   //Desarrollo Microfrontend
//     muestras_mfend: getDashboarURL('muestras_prod'), //Desarrollo Microfrontend
//     url_Dx: getDashboarURL('urlDX_prod') 
// };




// export const APP_CONFIG = {
//     api_auth: getApiURL('dev155'),
//     api_url: getApiURL('dev155'),
//     website_url: getWebsiteURL('dev155'),
//     asiVamos_url: getAsiVamosURL('asiVamos_prod'),
//     dashboar_url: getDashboarURL('dashboard_pro'),
//     report_url: getReportesURL('public_pilot'),
//     averias_mfend: getDashboarURL('averias_dev'), //Desarrollo Microfrontend
//     muestras_mfend: getDashboarURL('muestras_dev'), //Desarrollo Microfrontend
// };

// export const APP_CONFIG = {
//     api_auth: getApiURL('public_pilot'),
//     api_url: getApiURL('public_pilot'),
//     website_url: getWebsiteURL('public_pilot'),
//     asiVamos_url: getAsiVamosURL('asiVamos_pilot'),
//     dashboar_url: getDashboarURL('dashboard_dev'),
//     report_url: getReportesURL('public_pilot'),
//     averias_mfend: getDashboarURL('averias_dev'), //Desarrollo Microfrontend
//     muestras_mfend: getDashboarURL('muestras_dev'), //Desarrollo Microfrontend
//     webcilindros_mfend: getDashboarURL('webcil_dev'), //Desarrollo WebCilindros
//     fininv_mfend: getDashboarURL('fininv_dev'), //Desarrollo WebCilindros
//     soldesc_mfend: getDashboarURL('soldesc_dev'), //Desarrollo solicitud descuento
//     url_Dx: getDashboarURL('urlDX_dev'),
//     asiVamo_mfend: getDashboarURL('urlAsiVamos_prod'),
//     condComercial_mfend: getDashboarURL('condComercial_dev')  
// };

