import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';
import { ToastService } from './toast.service';

@Injectable({
	providedIn: 'root'
})
export class SupportService extends BaseService {

	constructor(
        private _http: HttpClient,
		private _auth: AuthService,
		private _toast: ToastService
	) {
		super(_http);

		this.model = (construct: any) => {
			return Object.assign({}, construct);
		};
    }
    
    openFreshdesk() {
		if (environment.envName === 'dgt')
			this._toast.info('Servicio de soporte NO DISPONIBLE en ambiente de PRUEBAS');
		else {
			let user = this._auth.currentUserValue;
	
			if (!user)
				openFD();
			else
				openFD(user['Nombre'], user['Email']);
		}
	}

	openTutorialsDoc() {
		window.open('http://transportes.brinsa.com.co/tm/adjunto_v.asp?idadjunto=1044279&v=1&tk1=ULhtHL7l4690665E-027TjZJXaZ08532654GZgcVXf70201092', '_blank');
	}
}

function openFD(name = '', email = '') {
	window['FreshworksWidget']('identify', 'ticketForm', {
		name: name,
		email: email,
    });
    
    window['FreshworksWidget']('open');
}