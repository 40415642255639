import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PagesModule } from './pages/pages.module';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { reducers } from './reducers';
import { EffectsModule } from '@ngrx/effects';
import { AgmCoreModule } from '@agm/core';
import { RouteHandlerModule } from './core/route-handler/route-handler.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthInterceptor } from './providers/interceptors/auth.interceptor';
import { ErrorInterceptor } from './providers/interceptors/error.interceptor';

import { NoopAnimationsModule } from '@angular/platform-browser/animations';

// Librería de notificaciones y toast
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';

// Licencia Ag-Grid
import { LicenseManager } from "ag-grid-enterprise";
LicenseManager.setLicenseKey("CompanyName=BSS LTDA 830085020,LicensedGroup=BSS LTDA,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-012627,ExpiryDate=28_December_2021_[v2]_MTY0MDY0OTYwMDAwMA==0abb0b640051f261cd8b1ab626ec7edf");

//Ingresar sin Token
import { LoginTokenComponent } from './authToken/login-token/login-token.component';
import { RedirecionarComponent } from './redirecionar/redirecionar.component';

@NgModule({
	imports: [
		BrowserModule.withServerTransition({ appId: 'elastic-ui' }),
		BrowserAnimationsModule,
		HttpClientModule,
		StoreModule.forRoot(reducers),
		!environment.production ? StoreDevtoolsModule.instrument({ maxAge: 50 }) : [],
		EffectsModule.forRoot([]),
		AgmCoreModule.forRoot({
			apiKey: ""
		}),
		AppRoutingModule,
		CoreModule,
		PagesModule,
		RouteHandlerModule,
		SnotifyModule
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
		{ provide: 'SnotifyToastConfig', useValue: ToastDefaults},
    	SnotifyService
	],
	declarations: [AppComponent, LoginTokenComponent, RedirecionarComponent],
	bootstrap: [AppComponent]
})
export class AppModule { }
