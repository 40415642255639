import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { ROUTE_TRANSITION } from '../../../app.animation';
import { AuthService } from '../../../providers/auth.service';
import { FormControl, Validators, NgForm, FormGroup } from '@angular/forms';
import { ToastService } from '../../../providers/toast.service';

@Component({
	selector: 'elastic-recovery-password',
	templateUrl: './recovery-password.component.html',
	styleUrls: ['./recovery-password.component.scss'],
	animations: [...ROUTE_TRANSITION],
	// tslint:disable-next-line:use-host-property-decorator
	host: { '[@routeTransition]': '' }
})
export class RecoveryPasswordComponent implements OnInit {

	pass = '';

	passConfirm = '';

	token = '';

	user: string;

	form: FormGroup;

	loading: boolean;

	errorMsg: string;

	forLX: boolean;

	capsActived: boolean;

	showPassword: boolean;

	showPasswordRec: boolean;

	resendDisabled: boolean;

	constructor(
		private _router: Router,
		private _authService: AuthService,
		private _toast: ToastService,
		private _activatedRoute: ActivatedRoute,
	) {
		this._activatedRoute.params.subscribe(params => {
			if (params['token'])
				this.token = params['token'];
		});

		this._activatedRoute.queryParams.subscribe(qparams => {
			this.forLX = qparams['lx'];
			this.user = qparams['user'];
		});

		this.form = new FormGroup({
			pass: new FormControl(''),
            passConfirm: new FormControl(''),
            token: new FormControl(''),
		});
	}

	ngOnInit() {
		let passValidators = [];

		// Si no es recuperación en LX, aplica las validaciones regulares
		if (!this.forLX) {
			passValidators = [
				Validators.minLength(8), 
				Validators.maxLength(10),
				Validators.required
			];
		}
		
		let passConfirmValidators = [
			this.noEqual.bind(this),
			Validators.required
		];

		this.form.get('pass').setValidators(passValidators);
		this.form.get('passConfirm').setValidators(passConfirmValidators);

		this.form.get('pass').valueChanges.subscribe(val => {
			this.form.get('passConfirm').markAsTouched();
			this.form.get('passConfirm').updateValueAndValidity();
		});
	}

	/**
	 * Para validar si la contraseña coincide con la confirmación de contraseña
	 */
	noEqual(control: FormControl): { [s: string]: boolean } {
		if (this.form.get('pass').value && control.value !== this.form.get('pass').value) {
			return {
				'no-equal': true
			};
		}
		else {
			return null;
		}
	}

	send() {
		this.loading = true;
		this.errorMsg = '';

		this._authService.checkPassRecovery(this.form.value.token, this.form.value.pass, null).subscribe(response => {
			this._toast.success("Se ha cambiado la Contraseña correctamente");
			this._router.navigate(['/auth/login'], { queryParams: { autoLogin: `${this.user};${btoa(this.form.value.pass)}` } });
		}, (error) => {
			let err = error.error.ExceptionMessage || error.Message;
			this.errorMsg = err.split(' | ')[0];
			this._toast.error(`Error: ${err}`);
			this.loading = false; 
		}, () => { 
			this.loading = false; 
		});
	}

	/**
	 * Reenvía el código de verificación al correo del usuario
	 */
	resendCode() {
		this._authService.requestPassRecovery(this.user).subscribe(response => {
			this.resendDisabled = true;
			setTimeout(() => {
				this.resendDisabled = false;
			}, 30000);
		}, (error) => {
			let err = error.error.ExceptionMessage || error.Message;
			this.errorMsg = err.split(' | ')[0];
			
			if (this.errorMsg.includes('Se han encontrado dos o mas usuarios'))
				this.errorMsg += '. Comuníquese con el equipo de tecnología';

			this.loading = false; 
		}, () => { 
			this.loading = false;
		 });
	}


}
