import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ROUTE_TRANSITION } from "../../../app.animation";
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { AuthService } from '../../../providers/auth.service';
import { Subscription } from 'rxjs';
import { SystemService } from '../../../providers/system.service';
import { Utils } from '../../../core/utils/index';
import { SupportService } from 'app/providers/support.service';
import { ToastService } from '../../../providers/toast.service';


@Component({
	selector: "elastic-login",
	templateUrl: "./login.component.html",
	styleUrls: ["./login.component.scss"],
	animations: [...ROUTE_TRANSITION],
	// tslint:disable-next-line:use-host-property-decorator
	host: { "[@routeTransition]": "" }
})
export class LoginComponent implements OnInit, OnDestroy {

	loginError = '';

	authForm: FormGroup;

	urlParams: string;

	urlParamsSubscription: Subscription;

	loading: boolean;

	capsActived: boolean;

	showPassword: boolean;

	autoLogin: string;

	constructor(
		private _router: Router,
		private _activatedRoute: ActivatedRoute,
		private _auth: AuthService,
		private _supportService: SupportService,
		private _toast: ToastService,
		public systemService: SystemService,
	) { 
		
		this.authForm = new FormGroup({
			useremail: new FormControl('', Validators.required),
            password: new FormControl('', Validators.required),
		});

		this._activatedRoute.queryParams.subscribe((params) => {
			if (params['autoLogin'])
				this.autoLogin = params['autoLogin'];
		});
	}

	ngOnInit() { 
		this.authForm.valueChanges.subscribe((event) => {
			this.loginError = '';
		});

		if (this.autoLogin) {
			let user, pass;
			[user, pass] = this.autoLogin.split(';');
			pass = atob(pass);
			this.login(user, pass);
		}

	}

	ngOnDestroy() {
		if (this.urlParamsSubscription) {
			this.urlParamsSubscription.unsubscribe();
		}
	}

	login(useremail: string = '', password: string = '') {
		this.loading = true;
		this.loginError = '';

		this._auth.login(this.authForm.value.useremail || useremail, this.authForm.value.password || password).subscribe(user => {
			this.urlParamsSubscription = this._activatedRoute.queryParams.subscribe(queryParams => {
				if (queryParams['returnUrl']) {
					let route = decodeURIComponent(queryParams['returnUrl']).replace(/%3F/g, '?').replace(/%3D/g, '=');
					let routeParts = route.split('?');
					let angularRoute = routeParts[0];

					if (routeParts.length > 1) {
						let qParams = Utils.getURLQueryParams(route);
						this._router.navigate([angularRoute], { queryParams: qParams });
					}
					else
						this._router.navigate([angularRoute]);
				}
				else {
					this._router.navigate(["/"]);
				}

				setTimeout(() => {
					this.systemService.checkRelease();
				}, 300);
				this.loading = false;
			});
			
		}, (err) => {
			let errMsg = '';

			if (err.status === 401) {
				errMsg = err.error.ExceptionMessage || err.error.Message || "Usuario o contraseña incorrectos";
			}
			else {
				errMsg = err.error.ExceptionMessage || err.error.Message || "Error al iniciar sesión";
			}

			this.loginError = errMsg.split(' | ')[0];

			if (errMsg.split(' | ')[1])
				this._toast.error(errMsg.split(' | ')[1]);
			
			this.loading = false;
		}, () => {
			this.systemService.getEnvironment();
		});
		
	}

	checkCaps(event) {
		console.log('event :>> ', event);
		// let state =  event.getModifierState('CapsLock');
		// console.log('state :>> ', state);
	}

	openFreshdesk() {
		this._supportService.openFreshdesk();
	}
}