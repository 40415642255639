import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import * as moment from "moment";
import { NotificationService } from "app/providers/notification.service";
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';

import * as fromRoot from '../../reducers/index';
import * as layout from '../layout/shared/layout.action';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../../providers/auth.service';

@Component({
    selector: "elastic-quickpanel",
    templateUrl: "./quickpanel.component.html",
    styleUrls: ["./quickpanel.component.scss"]
})
export class QuickpanelComponent implements OnInit, OnDestroy {
    todayDay: string;
    todayDate: string;
    todayDateSuffix: string;
	todayMonth: string;
	
	notifications: any[];

	private _destroyed$ = new Subject(); // Subject para eliminar las subscripciones de forma masiva

    refreshInterval: any = 0;

    refreshIntervalTime = 180000; // Milisegundos

    constructor(
		private _notificationService: NotificationService,
		private _authService: AuthService,
        private _cd: ChangeDetectorRef,
		private _router: Router,
		private store: Store<fromRoot.State>,
	) {
		this._router.events.pipe(takeUntil(this._destroyed$)).subscribe((params) => {
			this.closeQuickpanel();
		});
	}

    ngOnInit() {
        this.todayDay = moment().format("dddd");
        this.todayDate = moment().format("Do");
        this.todayDate = this.todayDate.replace(/\D/g, "");
        this.todayDateSuffix = moment().format("Do");
        this.todayDateSuffix = this.todayDateSuffix.replace(/[0-9]/g, "");
        this.todayMonth = moment().format("MMMM");

        this._notificationService.notifications.pipe(takeUntil(this._destroyed$)).subscribe((data) => {
            this.notifications = data;
            this._cd.detectChanges();
        });

        this.refreshInterval = window.setInterval(() => {
            this._notificationService.initialize(this._authService.currentUserValue);
        }, this.refreshIntervalTime);
    }

    ngOnDestroy() {
        window.clearInterval(this.refreshInterval);
        this._destroyed$.next();
    	this._destroyed$.complete();
    }

    closeQuickpanel() {
		this.store.dispatch(new layout.CloseQuickpanelAction());
	}
}
