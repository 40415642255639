import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './core/layout/layout.component';
import { authRoutes } from './pages/auth/auth.routing';
import { MuestraOrderDialogComponent } from './pages/shipping-points/muestra-order-dialog/muestra-order-dialog.component';

// Guards
import { AuthGuard } from './providers/guards/auth.guard';
import { StatsGuard } from './providers/guards/stats.guard';
import { LoginTokenComponent } from './authToken/login-token/login-token.component';
import { RedirecionarComponent } from './redirecionar/redirecionar.component';


const routes: Routes = [
	{
		path: '',
		component: LayoutComponent,
		children: [
			{
				path: '',
				loadChildren: 'app/pages/home/home.module#HomeModule',
				pathMatch: 'full',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'productos',
				loadChildren: 'app/pages/products/products.module#ProductsModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'productos/galeria',
				loadChildren: 'app/pages/products/gallery/products-gallery.module#ProductsGalleryModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			// { path: 'pedidos/ingresarMuestra/:dpId',
			//   component: MuestraOrderDialogComponent,
			//   canActivate: [AuthGuard, StatsGuard]	
			// },
			// { path: 'pedidos/ingresarMuestra/:dpId',
			//   loadChildren: 'app/app.module#AppModule',
			// //   canActivate: [AuthGuard, StatsGuard]	
			// },
			{
				path: 'clientes',
				loadChildren: 'app/pages/customers/customers.module#CustomersModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'clientes/:id',
				loadChildren: 'app/pages/customers/customers.module#CustomersModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'prospectos',
				loadChildren: 'app/pages/customers/customers.module#CustomersModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'prospectos/:id',
				loadChildren: 'app/pages/customers/customers.module#CustomersModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'prospectos/:customer/puntos-envio',
				loadChildren: 'app/pages/shipping-points/shipping-points.module#ShippingPointsModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'prospectos/:customer/puntos-envio/:id',
				loadChildren: 'app/pages/shipping-points/shipping-points.module#ShippingPointsModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'clientes-en-error',
				loadChildren: 'app/pages/customers/customers.module#CustomersModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'clientes-en-error/:id',
				loadChildren: 'app/pages/customers/customers.module#CustomersModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'clientes/:customer/puntos-envio',
				loadChildren: 'app/pages/shipping-points/shipping-points.module#ShippingPointsModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'clientes/:customer/puntos-envio/:id',
				loadChildren: 'app/pages/shipping-points/shipping-points.module#ShippingPointsModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'puntos-de-envio',
				loadChildren: 'app/pages/shipping-points/shipping-points.module#ShippingPointsModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'puntos-de-envio-en-proceso',
				loadChildren: 'app/pages/shipping-points/shipping-points.module#ShippingPointsModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'puntos-de-envio-en-error',
				loadChildren: 'app/pages/shipping-points/shipping-points.module#ShippingPointsModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'puntos-de-envio/:customer',
				loadChildren: 'app/pages/shipping-points/shipping-points.module#ShippingPointsModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'puntos-de-envio/:customer/:id',
				loadChildren: 'app/pages/shipping-points/shipping-points.module#ShippingPointsModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'nc-comerciales/admin/encargados-comercial',
				loadChildren: 'app/pages/processes/admin/encargados-comercial/encargados-comercial.module#EncargadosComercialModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'nc-comerciales/admin/:process',
				loadChildren: 'app/pages/processes/admin/admin.module#AdminModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'administracion-comercial/encargados-comercial',
				loadChildren: 'app/pages/processes/admin/encargados-comercial/encargados-comercial.module#EncargadosComercialModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'administracion-comercial/revision-precios',
				loadChildren: 'app/pages/processes/admin/cond-comercial/cond-comercial.module#CondComercialModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'administracion-comercial/cond-comercial-excep',
				loadChildren: 'app/pages/processes/admin/cond-comercial/cond-comercial.module#CondComercialModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'administracion-comercial/condiciones-comerciales',
				loadChildren: 'app/pages/processes/admin/cond-comercial/cond-comercial.module#CondComercialModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'administracion-comercial/cond-comercial-panel',
				loadChildren: 'app/pages/processes/admin/cond-comercial/cond-comercial.module#CondComercialModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'administracion-comercial/mercaderistas',
				loadChildren: 'app/pages/processes/admin/cond-comercial/cond-comercial.module#CondComercialModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'administracion-comercial/asistentes-industria',
				loadChildren: 'app/pages/processes/admin/asistentes-industria/asistentes-industria.module#AsistentesIndustriaModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'administracion-comercial/aprobaciones',
				loadChildren: 'app/pages/processes/admin/processes-list/processes-list.module#ProcessesListModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'administracion-comercial/:process',
				loadChildren: 'app/pages/processes/admin/admin.module#AdminModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'nc-comerciales/:process/:subprocess/:idProcess',
				loadChildren: 'app/pages/agreements/agreements.module#AgreementsModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'nc-comerciales/:process/:subprocess',
				loadChildren: 'app/pages/agreements/agreements.module#AgreementsModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'nc-comerciales/:process',
				loadChildren: 'app/pages/agreements/agreements.module#AgreementsModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'aprobaciones',
				loadChildren: 'app/pages/processes/processes.module#ProcessesModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'aprobaciones/:category',
				loadChildren: 'app/pages/processes/processes.module#ProcessesModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'pages/profile',
				loadChildren: 'app/pages/profile/profile.module#ProfileModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'web-cilindros/admin/contenedores',
				loadChildren: 'app/pages/web-cilindros/cilindros/web-cilindros.module#WebCilindrosModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'web-cilindros/admin/historico',
				loadChildren: 'app/pages/web-cilindros/historico/historico.module#HistoricoModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'web-cilindros/recepciones',
				loadChildren: 'app/pages/web-cilindros/recepciones/recepciones.module#RecepcionesModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'web-cilindros/phds',
				loadChildren: 'app/pages/web-cilindros/phds/phds.module#PhdsModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'web-cilindros/despachos',
				loadChildren: 'app/pages/web-cilindros/despachos/despachos.module#DespachosModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'web-cilindros',
				loadChildren: 'app/pages/web-cilindros/cilindros/web-cilindros.module#WebCilindrosModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'web-cilindros/:type',
				loadChildren: 'app/pages/web-cilindros/cilindros/web-cilindros.module#WebCilindrosModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'web-cilindros/llenado',
				loadChildren: 'app/pages/web-cilindros/llenado/llenado.module#LlenadoModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'precios/:category',
				loadChildren: 'app/pages/listas-precios/listas-precios.module#ListasPreciosModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'precios/:category/:process',
				loadChildren: 'app/pages/listas-precios/listas-precios.module#ListasPreciosModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'administracion-comercial/:zonaCategory/:type',
				loadChildren: 'app/pages/zonas-promocionales/tipos-zona-list/tipos-zona-list.module#TiposZonaListModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'administracion-comercial/:zonaCategory/:type/:id',
				loadChildren: 'app/pages/zonas-promocionales/zonas-promocionales.module#ZonasPromocionalesModule',
				canActivate: [AuthGuard, StatsGuard],
				pathMatch: 'full'
			},
			{
				path: 'administracion-comercial/:zonaCategory/:type/detalle/:id',
				loadChildren: 'app/pages/zonas-promocionales/zona-promo-detalle/zona-promo-detalle.module#ZonaPromoDetalleModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'zonas-promocionales/:type',
				loadChildren: 'app/pages/zonas-promocionales/zonas-promocionales.module#ZonasPromocionalesModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'zonas-logisticas/:type',
				loadChildren: 'app/pages/zonas-promocionales/zonas-promocionales.module#ZonasPromocionalesModule',
				canActivate: [AuthGuard, StatsGuard]
			},				
			{
				path: 'cartera/:period',
				loadChildren: 'app/pages/portfolio/portfolio.module#PortfolioModule',
				canActivate: [AuthGuard, StatsGuard]
			},		
			{
				path: 'cartera',
				loadChildren: 'app/pages/portfolio/portfolio.module#PortfolioModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'cartera/:seller/periodo/:period/clientes',
				loadChildren: 'app/pages/portfolio-clients/portfolio-clients.module#PortfolioClientsModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'cartera/:seller/periodo/:period/clientes/:customer/documentos',
				loadChildren: 'app/pages/portfolio-documents/portfolio-documents.module#PortfolioDocumentsModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'carteraClientes/:period',
				loadChildren: 'app/pages/portfolio-clients/portfolio-clients.module#PortfolioClientsModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'carteraClientes',
				loadChildren: 'app/pages/portfolio-clients/portfolio-clients.module#PortfolioClientsModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'consulta-notas-credito',
				loadChildren: 'app/pages/portfolio/notas-credito/notas-credito.module#NotasCreditoModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'portafolio-productos',
				loadChildren: 'app/pages/processes/admin/portafolio-productos/portafolio-productos.module#PortafolioProductosModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'productos/:customer/portafolio',
				loadChildren: 'app/pages/products/products.module#ProductsModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'productos/:shipping/portafolioPE',
				loadChildren: 'app/pages/products/products.module#ProductsModule',
				canActivate: [AuthGuard, StatsGuard]
			},	
			{
				path: 'trademarketing/pedido-minimo',
				loadChildren: 'app/pages/pedidos/pedido-minimo/pedido-minimo.module#PedidoMinimoModule',
				canActivate: [AuthGuard, StatsGuard]
			},	
			{
				path: 'trademarketing/inventario',
				loadChildren: 'app/pages/pedidos/inventario-punto-envio/inventario-punto-envio.module#InventarioPuntoEnvioModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'trademarketing/gondola',
				loadChildren: 'app/pages/pedidos/espacio-gondola/espacio-gondola.module#EspacioGondolaModule',
				canActivate: [AuthGuard, StatsGuard]
			},	
			{
				path: 'pedidos/seguimiento',
				loadChildren: 'app/pages/pedidos/pedidos.module#PedidosModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'pedidos/historico',
				loadChildren: 'app/pages/pedidos/historico/historico.module#HistoricoPedidosModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'pedidos/resumido',
				loadChildren: 'app/pages/pedidos/historico/historico.module#HistoricoPedidosModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'promocionales',
				loadChildren: 'app/pages/promocionales/promocionales.module#PromocionalesModule',
			},		
			{
				path: 'trademarketing/actividad',
				loadChildren: 'app/pages/pedidos/actividad/actividad.module#ActividadModule',
				canActivate: [AuthGuard, StatsGuard]
			},	
			{
				path: 'trademarketing/rotacion',
				loadChildren: 'app/pages/pedidos/rotacion/rotacion.module#RotacionModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'trademarketing/exhibicion',
				loadChildren: 'app/pages/pedidos/exhibicion/exhibicion.module#ExhibicionModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'pedidos/ingresarPedido/:dpId/:cId',
				loadChildren: 'app/pages/shipping-points/make-order-dialog/make-order.module#MakeOrderModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{//Se necesita para Muestras
				path: 'pedidos/ingresarMuestra/:idPEnv',
				loadChildren: 'app/pages/shipping-points/muestra-order-dialog/muestra-order-dialog.module#MuestraOrderDialogModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{//Se crea nueva para el fininvt
				path: 'fininv',
				loadChildren: 'app/pages/fininv/admin.module#AdminModule',
				
			},
			{//Se crea nueva para el fininvt
				path: 'iframe/:url',
				loadChildren: 'app/pages/iframe/admin.module#AdminModule',
				canActivate: [AuthGuard, StatsGuard],	
			},
			{//Se necesita para Averias
				path: 'pedidos/ingresarAveria/:idPEnv',
				loadChildren: 'app/pages/shipping-points/averia-order-dialog/averia-order-dialog.module#AveriaOrderDialogModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			
			{//Rutas adicionales para Averias
					path: 'averias/:process',
					loadChildren: 'app/pages/averias/admin.module#AdminModule',
					// loadChildren: 'app/pages/processes/admin/admin.module#AdminModule',
					// canActivate: [AuthGuard, StatsGuard]
			},
			
			
			{
				path: 'bolsa',
				loadChildren: 'app/pages/bolsa/bolsa.module#BolsaModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'industria/ordenes-compra',
				loadChildren: 'app/pages/ordenes-compra/ordenes-compra.module#OrdenesCompraModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'ibc/admin/ibcs',
				loadChildren: 'app/pages/ibc/ibcs/ibcs.module#IBCsModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'ibc/admin/historico',
				loadChildren: 'app/pages/ibc/ibc-historico/ibc-historico.module#IBCHistoricoModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'ibc/recepciones',
				loadChildren: 'app/pages/ibc/ibc-recepciones/ibc-recepciones.module#IBCRecepcionesModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'ibc/despachos',
				loadChildren: 'app/pages/ibc/ibc-despachos/ibc-despachos.module#IBCDespachosModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'ibc',
				loadChildren: 'app/pages/ibc/ibcs/ibcs.module#IBCsModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'ibc/:type',
				loadChildren: 'app/pages/ibc/ibcs/ibcs.module#IBCsModule',
				canActivate: [AuthGuard, StatsGuard]
			},
			{
				path: 'ibc/llenado',
				loadChildren: 'app/pages/ibc/ibc-llenado/ibc-llenado.module#IBCLlenadoModule',
				canActivate: [AuthGuard, StatsGuard]
			},
		]
	},
	{
		path: 'auth',
		children: [
			...authRoutes
		]
	},
	{
		path: 'logintoken',
		component: LoginTokenComponent
	},
	{
		path: 'redireccionar',
		component: RedirecionarComponent
	},
	{ path: '**', redirectTo: '' } // Redirigir rutas no encontradas a la página principal u otra página de error

];

@NgModule({
	imports: [RouterModule.forRoot(routes, {
		initialNavigation: 'enabled',
		preloadingStrategy: PreloadAllModules,
		scrollPositionRestoration: 'top',
		anchorScrolling: 'enabled'
	})],
	exports: [RouterModule]
})
export class AppRoutingModule { }
