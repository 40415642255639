import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { APP_CONFIG } from 'app/config';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export abstract class SystemService {
    
    private environmentSubject: BehaviorSubject<string>;
    public environment: Observable<string>;
    
    public get environmentValue(): string {
        return this.environmentSubject.value;
    }

    public set environmentValue(v: string) {
        this.environmentSubject.next(v);
    }

    private _currentRelease: string;

    
    public get currentRelease(): string {
        return this._currentRelease;
    }

    
    public set currentRelease(v: string) {
        this._currentRelease = v;
    }

	constructor(private _http: HttpClient) {
        this.environmentSubject = new BehaviorSubject<string>('production');
        this.environment = this.environmentSubject.asObservable();

		this.getEnvironment().pipe(take(1)).subscribe((val: string) => {
            this.environmentSubject.next(val);
        });

        this.checkRelease();
    }
    
    getEnvironment(): Observable<any> {
        return this._http.get(`${APP_CONFIG.api_url}/system/environment`);
    }

    getDatasource(): Observable<string> {
        return this._http.get<string>(`${APP_CONFIG.api_url}/system/datasource`);
    }

    getLibrary(): Observable<string> {
        return this._http.get<string>(`${APP_CONFIG.api_url}/system/library`);
    }

    getVersion(short: boolean = true): Observable<any> {
        let result = this._http.get(`${APP_CONFIG.api_url}/system/version`);

        if (short) {
            result = result.pipe(map((r: string) => r.split(' ')[0]));
        }
        return result;
    }

    getSystemInfo(): Observable<any> {
        return this._http.get(`${APP_CONFIG.api_url}/system/info`);
    }

    async checkRelease() {
        let release = '';
        try {
            let url = `assets/release.info`;
            release = (await this._http.get(url, { responseType: 'text' }).toPromise() as string);
        } catch (error) {}

        let stored = localStorage.getItem('release') || '';
        localStorage.setItem('release', release);

        if (stored && stored !== release || !stored && release) {
            console.log('new release, reloading');
            window.location.reload();
        }
        
	}
}