import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { APP_CONFIG } from '../config';
import { UserService } from './user.service';
import { NotificationService } from './notification.service';
import { ToastService } from './toast.service';
import * as moment from 'moment';
import { SnotifyPosition } from 'ng-snotify';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthService {

	private currentUserSubject: BehaviorSubject<any>;
	
	public currentUser: Observable<any>;
	
	prueba = false;

	private STORAGE_USER_KEY = 'current-user';

	private STORAGE_ID_KEY = 'identity';
	private readonly expirationKey = 'expirationTime';

	private STORAGE_VENDORS_KEY = 'user-vendors';

    constructor(
		private _http: HttpClient,
		private _userService: UserService,
		private _notificationService: NotificationService,
		private _toast: ToastService,
		private _router:Router
		
	) {
        this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('current-user')));
		this.currentUser = this.currentUserSubject.asObservable();

		// this.loadUserNotifications();
    }

    public get currentUserValue(): any {
        return this.currentUserSubject.value;
    }

    login(Username: string, Password: string) {
		// return this._http.post<any>(`${APP_CONFIG.api_url}/auth/login`, { Username, Password, App: "ENLACEBRINSA" })
		return this._http.post<any>(`${APP_CONFIG.api_auth}/auth/login`, { Username, Password, App: "ENLACEBRINSA" })
			.pipe(map(userAuthInfo => {
				console.log('del mapa login', userAuthInfo);
				// El login es exitoso si se recibió en la respuesta la token JWT
				if (userAuthInfo && (userAuthInfo.Token || userAuthInfo.token) && !this.currentUserValue) {
					this._setSession(userAuthInfo);
					this.getUserVendors();
					this.doPostLogin();
				}

				return userAuthInfo;
			}));
	}

	loginToken(token:string){
		console.log('token:', token);

		let http_options = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${token}`
		  });

		return this._http.get(`${APP_CONFIG.api_auth}/auth/echouser?App=ENLACEBRINSA`,{ headers: http_options })
		.subscribe( (userInfo:any) => {
			userInfo.Token = token;
			console.log('new',userInfo);
			this._setSession(userInfo);
			this.getUserVendors();
			this.doPostLogin();
			this._router.navigateByUrl('/');
		},
		erro => this._router.navigateByUrl('/')
		
		);
		// .pipe(map((userAuthInfo:any) => {
		// 	// El login es exitoso si se recibió en la respuesta la token JWT
		// 	({ ...userAuthInfo, Token: token});
		// 		// userAuthInfo.Token = token;
		// 		console.log('Respuesta del Map', userAuthInfo);
		// 		this._setSession(userAuthInfo);
		// 		this.getUserVendors();
		// 		this.doPostLogin();

		// 	return userAuthInfo;
		// }));
	}

	/**
	 * Almacena los datos del usuario junto a la token JWT en el localStorage para mantener persistencia de la sesión
	 * @param authResult resultado de una autenticación exitosa (usuario y token recibidos correctamente)
	 */
	private _setSession(authResult: any) {
		if (authResult['Token'] || authResult['token'])
			localStorage.setItem(this.STORAGE_ID_KEY, (authResult['Token'] || authResult['token']));

			// Intervalo de 8 horas en milisegundos
			const expirationTimestamp = new Date().getTime() + 8 * 60 * 60 * 1000;
			localStorage.setItem(this.expirationKey, expirationTimestamp.toString());	

		delete authResult['Token']; // Quita la token de la información del usuario para mantenersa sólo en la key 'identity'
		delete authResult['token']; // Quita la token de la información del usuario para mantenersa sólo en la key 'identity'
		localStorage.setItem(this.STORAGE_USER_KEY, JSON.stringify(authResult));
		this.currentUserSubject.next(authResult);
	}

	//Validamos si paso las 8 horas
	checkTokenExpiration() {
		const expirationTimestamp = localStorage.getItem(this.expirationKey);
	
		if (expirationTimestamp) {
		  const currentTimestamp = new Date().getTime();
		  console.log('currentTimestamp',currentTimestamp);
		  console.log('expirationTimestamp',expirationTimestamp);
		  if (currentTimestamp > +expirationTimestamp) {
			this.logout();
		  }
		}
	  }

    logout() {
		// Elimina las variables de sesión del localStorage (Usuario y JWT)
		// TODO: Request a la API para invalidar la token
        localStorage.removeItem(this.STORAGE_USER_KEY);
        localStorage.removeItem(this.STORAGE_ID_KEY);
        localStorage.removeItem(this.STORAGE_VENDORS_KEY);
		this.currentUserSubject.next(null);
		this._notificationService.unsubscribeFromNotifications();
		this._router.navigateByUrl('/');
	}
	
	/**
	 * Retorna la token JWT de la sesión actual
	 * @param bearer Es una token 'Bearer' (e incluir dicha keyword)
	 */
	getIdentityToken(bearer: boolean = false) {
		if (localStorage.getItem(this.STORAGE_ID_KEY)) {
			return `${bearer ? 'Bearer ' : '' + localStorage.getItem(this.STORAGE_ID_KEY)}`;
		}
		else {
			return null;
		}
	}

	
	requestPassRecovery(user: string) {
		return this._http.post(`${APP_CONFIG.api_url}/auth/pass-recovery/request?user=${user}`, null);
	}

	checkPassRecovery(PRToken: string, Password: string, OldPassword: string = null) {
		return this._http.post(`${APP_CONFIG.api_url}/auth/pass-recovery/check`, { Password, PRToken, OldPassword });
	}

	async getUserVendors() {
		const usr = this.currentUserValue['Usuario'];
		await this._userService.getUserVendors(usr);
		localStorage.setItem(this.STORAGE_VENDORS_KEY, JSON.stringify(this._userService.userVendorsValue));
	}

	changeUserVendor(vendor: any) {
		let authInfo = this.currentUserValue;
		authInfo['Vendedor'] = vendor;
		this._setSession(authInfo);
	}

	async refreshProfile() {
		const jwt = localStorage.getItem(this.STORAGE_ID_KEY);
		const freshAuthInfo = await this._userService.getUserInfoByJWT(this.currentUserValue['Usuario'], jwt).toPromise();
		if (!freshAuthInfo) {
			this.logout();
			throw new Error("La sesión del usuario actual ha expirado");
		}
		this._setSession(freshAuthInfo);
		this.doPostLogin();
	}

	async doPostLogin() {
		await this.loadUserNotifications();
		// Si el usuario tiene notificaciones, genera un mensaje de alerta para llamar la atención
		this.showUserNotificationsAlert();
		// this.currentUser.subscribe(user => {
		// 	console.log('user :>> ', user);
		// 	if (!user) {
		// 		this._notificationService.unsubscribeFromNotifications();
		// 	}
		// });
	}

	async loadUserNotifications() {
		await this._notificationService.initialize(this.currentUserValue); // Carga las notificaciones para usuario actual
	}

	showUserNotificationsAlert() {
		if (this._notificationService.activeNotificationsCountValue > 0) {
			let alert = '<div class="custom-snotify"><p><strong>Últimas Notificaciones</strong></p><ul>';

			this._notificationService.notificationsValue.slice(0, 3).forEach(notif => {
				alert += `<li>${notif.process}, <small>${moment(notif.date).fromNow()}</small></li>`;
			});

			alert += '</ul></div>';
			this._toast.custom('info', '', {
				timeout: 0,
				showProgressBar: true,
				closeOnClick: true,
				pauseOnHover: false,
				position: SnotifyPosition.rightTop,
				html: alert,
				animation: {
					enter: 'bounceIn',
					exit: 'bounceIn',
					time: 600
				}
			});
		}
	}
}