import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';


/**
 * Filtra sobre propiedades específicas de un objeto o sobre cadenas de texto
 */
@Pipe({
	name: 'filterAsync',
	pure: false
})
export class FilterAsyncPipe implements PipeTransform {

	transform(collection: any[], criteria: any, accurate: boolean = false): any {

		if (collection === null || !collection.length) {
			return [];
		}
		else {
			return Observable.create((observer) => {
				observer.next(collection.filter((item: Object) => {
					let itMatches = true;
	
					if (typeof item === 'object') {
	
						// Check item properties coincidences for each criteria properties
						if (typeof criteria === 'object') {
							for (const key of Object.keys(criteria)) {
								itMatches = itMatches && item.hasOwnProperty(key) && this.checkIfMatches(item[key], criteria[key], accurate);
							}
						}
						else { // Check if criteria matches with any item property	
							itMatches = false;
	
							for (const key of Object.keys(item)) {
								itMatches = itMatches || this.checkIfMatches(item[key], criteria, accurate);
							}
						}
					}
					else {
						itMatches = this.checkIfMatches(item, criteria, accurate);
					}
	
					return itMatches;
				}));
				
				observer.complete();
			});
		}
	}

	checkIfMatches(ref: any, target: any, _accurate: boolean = false): boolean {

		let itMatches = true;

		if (_accurate) {
			itMatches = ref === target;
		}
		else {
			if (typeof ref === 'string' && typeof target === 'string') {
				itMatches = ref.toLowerCase().indexOf(target.toLowerCase()) !== -1;
			}
			else {
				itMatches = ref === target;
			}
		}

		return itMatches;
	}

}
