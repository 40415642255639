import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ForgotPasswordComponent } from './forgot-password.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PromptDialogComponent } from '../../../components/prompt-dialog/prompt-dialog.component';
import { PromptDialogModule } from '../../../components/prompt-dialog/prompt-dialog.module';
import {
	MatButtonModule,
	MatCheckboxModule,
	MatInputModule,
	MatSnackBarModule
} from "@angular/material";

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		FormsModule,
		FlexLayoutModule,
		PromptDialogModule,
		MatInputModule,
		MatButtonModule,
		MatCheckboxModule,
		MatSnackBarModule
	],
	entryComponents: [PromptDialogComponent],
	declarations: [ForgotPasswordComponent]
})
export class ForgotPasswordModule { }
