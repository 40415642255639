import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../reducers/index';
import * as layout from '../layout/shared/layout.action';
import { MatRadioChange, MatSelectChange } from '@angular/material';
import { componentDestroyed } from '../utils/component-destroyed';
import { takeUntil } from 'rxjs/operators';
import { UIConfigService } from '../../providers/ui-config.service';

@Component({
	selector: 'elastic-settings',
	templateUrl: './settings.component.html',
	styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, OnDestroy {

	cardElevationClass: string;

	currentOptionChecked: any;

	constructor(
		private store: Store<fromRoot.State>,
		private _uiConfigService: UIConfigService
	) {
	}

	ngOnInit() {
		this.store.select(fromRoot.getCardElevation).pipe(
			takeUntil(componentDestroyed(this))
		).subscribe((elevation) => {
			this.cardElevationClass = elevation;
		});

		// Inicializa la el layout seleccionado con respecto a la configuración previa
		if (this._uiConfigService.uiConfigValue && this._uiConfigService.uiConfigValue['layoutMode']) {
			this._setLayout(this._uiConfigService.uiConfigValue['layoutMode']);
		}
	}

	private _setLayout(value: any) {
		// No permite seleccionar Beta (bug en el sidenav en chrome)
		this.store.dispatch(new layout.SelectLayoutAction(value));
		this._uiConfigService.setLayoutMode(value);
		this.currentOptionChecked = value;
	}
	
	setLayoutFromUI(radioEvent: MatRadioChange) {
		this._setLayout(radioEvent.value);
	}

	setCardElevation(selectEvent: MatSelectChange) {
		this.store.dispatch(new layout.SetCardElevationAction(selectEvent.value));
	}

	ngOnDestroy() {
	}
}
