import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable, of } from "rxjs";

import { AuthService } from '../auth.service';
import { catchError } from "rxjs/operators";
import { ToastService } from "../toast.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(
        private _authService: AuthService,
        private _router: Router,
        private _toast: ToastService,
    ) {

    }

    intercept(req: HttpRequest<any>,
        next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(catchError((error: any, caught: Observable<any>) => {
            this.handleAuthError(error);
            return of(error);
        }));
    }

    private handleAuthError(err: HttpErrorResponse): Observable<any> {
        // Si es un error de autorización pero no es en el login
        if (err.status === 401 && !err.url.includes("/auth/login")) {
            this._authService.logout();
            this._router.navigate(['/auth/login']);
        }
        else {
            // console.log('err :>> ', err);
            // // Excluye el error con el archivo de tracking de versión
            // if (!err.message.includes('release.info'))
            //     this._toast.error(err.message);
        }
        
        throw err;
    }

    private getErrorMessage() {
        return '';
    }
}