import { Pipe, PipeTransform } from '@angular/core';


/**
 * Filtra sobre propiedades específicas de un objeto o sobre cadenas de texto
 */
@Pipe({
	name: 'filter',
	pure: false
})
export class FilterPipe implements PipeTransform {

	transform(collection: any[], criteria: any, accurate: boolean = false, disyuntive: boolean = false): any {

		if (!collection || (collection && !collection.length)) {
			return [];
		}
		else {
			return collection.filter((item: Object) => {
				let itMatches = !disyuntive;

				if (typeof item === 'object') {

					// Check item properties coincidences for each criteria properties
					if (typeof criteria === 'object') {
						for (const key of Object.keys(criteria)) {
							if (!disyuntive)
								itMatches = itMatches && item.hasOwnProperty(key) && this.checkIfMatches(item[key], criteria[key], accurate);
							else
								itMatches = itMatches || (item.hasOwnProperty(key) && this.checkIfMatches(item[key], criteria[key], accurate));
						}
					}
					else { // Check if criteria matches with any item property	
						itMatches =  false;

						for (const key of Object.keys(item)) {
							itMatches = itMatches || this.checkIfMatches(item[key], criteria, accurate);
						}
					}
				}
				else {
					itMatches = this.checkIfMatches(item, criteria, accurate);
				}

				return itMatches;
			});
		}
	}

	checkIfMatches(ref: any, target: any, _accurate: boolean = false): boolean {

		let itMatches = true;

		if (_accurate) {
			itMatches = ref === target;
		}
		else {
			if (typeof target === 'string') {
				if (!ref)
					return false;
				else
					itMatches = ref.toString().toLowerCase().indexOf(target.toLowerCase()) !== -1;
			}
			else {
				itMatches = ref === target;
			}
		}

		return itMatches;
	}

}
