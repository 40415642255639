import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoginComponent } from "./login.component";
import { RouterModule } from "@angular/router";
import { FlexLayoutModule } from "@angular/flex-layout";
import {
    MatButtonModule,
    MatCheckboxModule,
    MatInputModule
} from "@angular/material";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatIconModule, MatTooltipModule } from '@angular/material';
import { DirectivesModule } from '../../../directives/directives.module';

@NgModule({
    imports: [
        RouterModule,
		FormsModule,
        ReactiveFormsModule,
        DirectivesModule,
        FlexLayoutModule,
        MatInputModule,
        MatButtonModule,
        MatCheckboxModule,
        MatIconModule,
        MatTooltipModule,
        CommonModule
    ],
    declarations: [LoginComponent]
})
export class LoginModule {}
