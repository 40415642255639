import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SnotifyPosition, SnotifyService, SnotifyToastConfig } from 'ng-snotify';
import { Utils } from '../core/utils/index';

@Injectable({
	providedIn: 'root'
})
export class ToastService {
    
	constructor(
        private _snotify: SnotifyService,
        private sanitized: DomSanitizer
    ) { 

    }

    private _getHtml(text: string) {
        return Utils.isHtml(text) ? text : null;
    }
    
    success(message: string, indeterminate?: boolean, position: string = 'rightBottom') {
        this._snotify.success(message, {
            timeout: indeterminate ? 0 : 6000,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            position: SnotifyPosition[position],
            html: this._getHtml(message),
        });
    }

    error(message: string) {
        this._snotify.error(message, {
            timeout: 0,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            bodyMaxLength: 300,
            html: this._getHtml(message),
        });
    }

    warning(message: string, indeterminate?: boolean, position: string = 'rightBottom') {
        this._snotify.warning(message, {
            timeout: indeterminate ? 0 : 6000,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            position: SnotifyPosition[position],
            html: this._getHtml(message),
        });
    }

    info(message: string, indeterminate?: boolean, position: string = 'rightBottom') {
        this._snotify.info(message, {
            timeout: indeterminate ? 0 : 6000,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            position: SnotifyPosition[position],
            html: this._getHtml(message)
        });
    }

    custom(type: "info" | "success" | "warning" | "error", message: string, config: SnotifyToastConfig) {
        this._snotify[type](message, config);
    }

}
