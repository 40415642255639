import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FilterPipe } from './filter.pipe';
import { FilterAsyncPipe } from './filter-async.pipe';
import { TextToHtmlPipe } from './text-to-html.pipe';
import { DateFromNowPipe } from './date-from-now.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';


@NgModule({
    declarations: [
        FilterPipe,
        FilterAsyncPipe,
        TextToHtmlPipe,
        DateFromNowPipe,
        SafeHtmlPipe
    ],
    imports: [ CommonModule ],
    exports: [
        FilterPipe,
        FilterAsyncPipe,
        TextToHtmlPipe,
        DateFromNowPipe,
        SafeHtmlPipe
    ],
    providers: [],
})
export class PipesModule {}