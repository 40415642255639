import { Component, OnInit } from "@angular/core";
import { SidenavItem } from "../sidenav/sidenav-item/sidenav-item.model";
import * as fromRoot from "../../reducers/index";
import * as fromSidenav from "../sidenav/shared/sidenav.action";
import { SetCurrentlyOpenByRouteAction } from "../sidenav/shared/sidenav.action";
import { Store } from "@ngrx/store";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import {
    SelectLayoutAction,
    SetCardElevationAction
} from "../layout/shared/layout.action";

@Component({
    selector: "elastic-route-handler",
    templateUrl: "./route-handler.component.html",
    styleUrls: ["./route-handler.component.scss"]
})
export class RouteHandlerComponent implements OnInit {

    constructor(
        private store: Store<fromRoot.State>,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        // Set Sidenav Currently Open on Page load
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.store.dispatch(
                    new SetCurrentlyOpenByRouteAction(event.urlAfterRedirects)
                );
            }
        });

        // You can use ?layout=beta to load the page with Layout Beta as default
        // Same with ?elevation=5 (anything from 0 to 24)
        this.route.queryParamMap.subscribe(params => {
            const layout = params.get("layout");

            switch (layout) {
                case "alpha": {
                    this.store.dispatch(new SelectLayoutAction("alpha"));
                    break;
                }

                case "beta": {
                    this.store.dispatch(new SelectLayoutAction("beta"));
                    break;
                }

                case "gamma": {
                    this.store.dispatch(new SelectLayoutAction("gamma"));
                    break;
                }
            }

            const elevation = params.get("elevation");

            if (elevation) {
                this.store.dispatch(
                    new SetCardElevationAction("card-elevation-z" + elevation)
                );
            }
        });

        // Define Menu Items here

        // Top Level Item (The item to click on so the dropdown opens)
        const dashboard = new SidenavItem({
            name: "Dashboard",
            icon: "dashboard",
            subItems: [],
            position: 1
        });

        // Sub Items for the Top Level Item (The items shown when you clicked on the dropdown item)
        // Note: The Top Level Item is added as "parent" in those items, here "dashboard" (variable from above)
        const dashboardSubItems = [
            new SidenavItem({
                name: "Dashboard",
                route: "/",
                parent: dashboard,
                subItems: [],
                position: 1,
                routerLinkActiveOptions: {
                    exact: true
                }
            }),
            new SidenavItem({
                name: "All-In-One Board",
                route: "/dashboard/all-in-one",
                parent: dashboard,
                subItems: [],
                position: 1
            }),
            new SidenavItem({
                name: "CRM Dashboard",
                route: "/dashboard/crm",
                parent: dashboard,
                subItems: [],
                position: 1
            })
        ];

        // Push the just created Sub Items into the Top Level Item
        dashboard.subItems.push(...dashboardSubItems);

        // Procesos
        const processes = new SidenavItem({
            name: "Bandeja de procesos",
            icon: "mail",
            route: "/procesos",
            subItems: [],
            position: 1
        });

        // Precios
        const prices = new SidenavItem({
            name: "Precios",
            icon: "attach_money",
            route: "/precios",
            subItems: [],
            position: 1
        });

        // Promocionales
        const SAC = new SidenavItem({
            name: "Sistema de administración comercial",
            icon: "attach_money",
            subItems: [],
            position: 1
        });

        const promotional = new SidenavItem({
            name: "Zonas Promocionales",
            icon: "attach_money",
            route: "/zonas-promocionales",
            subItems: [],
            position: 1
        });

        // Productos
        const products = new SidenavItem({
            name: "Productos",
            icon: "local_activity",
            route: "/productos",
            subItems: [],
            position: 1
        });

        // Clientes
        const customers = new SidenavItem({
            name: "Clientes",
            icon: "local_activity",
            route: "/clientes",
            subItems: [],
            position: 1
        });

        // Pedidos
        const orders = new SidenavItem({
            name: "Pedidos",
            icon: "local_activity",
            route: "/pedidos",
            subItems: [],
            position: 1
        });

        // Contratos
        const contracts = new SidenavItem({
            name: "Contratos",
            icon: "local_activity",
            route: "/contratos",
            subItems: [],
            position: 1
        });

        // Solicitudes
        const requests = new SidenavItem({
            name: "Solicitudes",
            icon: "local_activity",
            route: "/solicitudes",
            subItems: [],
            position: 1
        });

        // Cartera
        const portfolio = new SidenavItem({
            name: "Cartera",
            icon: "local_activity",
            route: "/cartera",
            subItems: [],
            position: 1
        });
               
        const pages = new SidenavItem({
            name: "Pages",
            icon: "library_books",
            subItems: [],
            position: 1,
            visible: false
        });

        const pagesSubItems = [
            new SidenavItem({
                name: "Profile",
                route: "/pages/profile",
                parent: pages,
                subItems: [],
                position: 1
            }),
            new SidenavItem({
                name: "Projects",
                route: "/pages/projects",
                parent: pages,
                subItems: [],
                position: 1
            }),
            new SidenavItem({
                name: "Project Details",
                route: "/pages/project-details",
                parent: pages,
                subItems: [],
                position: 1
            })
        ];

        pages.subItems.push(...pagesSubItems);

        

        // Send the created Menu structure to Redux/ngrx (you only need to send the Top Level Item, all dropdown items will be added automatically)
        // this.store.dispatch(new fromSidenav.AddSidenavItemAction(dashboard));
        // this.store.dispatch(new fromSidenav.AddSidenavItemAction(processes));
        // this.store.dispatch(new fromSidenav.AddSidenavItemAction(prices));
        // this.store.dispatch(new fromSidenav.AddSidenavItemAction(promotional));
        // this.store.dispatch(new fromSidenav.AddSidenavItemAction(products));
        // this.store.dispatch(new fromSidenav.AddSidenavItemAction(customers));
        // this.store.dispatch(new fromSidenav.AddSidenavItemAction(orders));
        // this.store.dispatch(new fromSidenav.AddSidenavItemAction(contracts));
        // this.store.dispatch(new fromSidenav.AddSidenavItemAction(requests));
        // this.store.dispatch(new fromSidenav.AddSidenavItemAction(pages));
        // this.store.dispatch(new fromSidenav.AddSidenavItemAction(inbox));
    }
}
