import { Component } from '@angular/core';
import { MediaReplayService } from './core/utils/media-replay.service';
import { SystemService } from './providers/system.service';
import { AuthService } from './providers/auth.service';

@Component({
	selector: 'elastic-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {

	//noinspection JSUnusedLocalSymbols
	constructor(
		mediaReplay: MediaReplayService, // workaround for Flex-Layout to receive the initial value
		private _systemService: SystemService,
		private authService:AuthService,
	) {
		// Nothing here.
		// You probably want to go to /core/layout/ :)
		this.setEnvClass();
		console.log('Siempre ingresa aquí');
		this.authService.checkTokenExpiration();
	}

	setEnvClass() {
		this._systemService.environment.subscribe(val => {
			document.body.className = val;
		});
	}
}
