import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { 
    MatDialogModule, 
    MatButtonModule
} from '@angular/material';


import { PromptDialogComponent } from './prompt-dialog.component';
import { MatIconModule } from '@angular/material';
import { PipesModule } from '../../pipes/pipes.module';

@NgModule({
    declarations: [
        PromptDialogComponent
    ],
    imports: [ 
        CommonModule,
        PipesModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule
    ],
    exports: [
        PromptDialogComponent
    ],
    providers: [],
})
export class PromptDialogModule {}