import { Routes } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { RegisterComponent } from "./register/register.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { RecoveryPasswordComponent } from "./recovery-password/recovery-password.component";

export const authRoutes: Routes = [
    {
        path: "login",
        component: LoginComponent
    },
    {
        path: "register",
        component: RegisterComponent
    },
    {
        path: "forgot-password",
        component: ForgotPasswordComponent
    },
    {
        path: "pass-recovery",
        component: RecoveryPasswordComponent
    },
    {
        path: "pass-recovery/:token",
        component: RecoveryPasswordComponent
    }
];
