import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';


/**
 * Filtra sobre propiedades específicas de un objeto o sobre cadenas de texto
 */
@Pipe({
	name: 'dateFromNow',
	pure: true
})
export class DateFromNowPipe implements PipeTransform {

	transform(value: any, args?: any): any {
		let ms = moment(value, "DD/MM/YYYY HH:mm:ss").utc().diff(moment(args, "DD/MM/YYYY HH:mm:ss").utc());
		let d = moment.duration(ms);
		return Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss");
	}
}
