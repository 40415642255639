import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { InboxTableItem } from '../core/models/inbox-table-item.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root'
})
export class ProcessService extends BaseService {

	modelName = 'processes';

	private pendingCountSubject: BehaviorSubject<any>;
	
	public pendingCount: Observable<any>;

	constructor(
		private _http: HttpClient,
		private _authService: AuthService,
	) {
		super(_http);

		this.model = (construct: any) => {
			return Object.assign({}, construct);
		};

		this.pendingCountSubject = new BehaviorSubject<any>(0);
		this.pendingCount = this.pendingCountSubject.asObservable();
	}

	getByUserAndName(name: string, user: string, state: string = null, id: string = '', limit: number = 50, page: number = 1) {
		// let url = `${this.url}/${this.modelName}/query/${name}/${user}`;
		let url = `${this.url}/${this.modelName}/query/null/${user}`;

		if (state)
			url += `/${state}`;

		url += `?limit=${limit}&page=${page}&id=${id}`;

		return this.http.get(url).pipe(
			map((res: any[]) => {
				this.refreshCounts();
				return this.mapListToModelList(res);
			})
		);
	}

	getByRef(ref: string, id: string) {
		// let url = `${this.url}/${this.modelName}/query/${name}/${user}`;
		let url = `${this.url}/${this.modelName}/ref/${ref}/${id}`;

		return this.http.get(url);
	}

	getManyByRef(ref: string) {
		// let url = `${this.url}/${this.modelName}/query/${name}/${user}`;
		let url = `${this.url}/${this.modelName}/ref/${ref}`;

		return this.http.get<any[]>(url);
	}

	getCountByUserAndName(name: string, user: string, state: string = null) {
		let url = `${this.url}/${this.modelName}/count/null/${user}`;

		if (state)
			url += `/${state}`;

		return this.http.get(url);
	}

	setStarred(idProcess: number, value: boolean) {
		if (!idProcess) 
			return;

		let url = `${this.url}/${this.modelName}/setstarred/${idProcess}/${value}`;

		return this.http.post(url, null);
	}

	setAsApproved(idProcess: number) {
		let user = this._authService.currentUserValue['Usuario']; //Variable nueva flujo Johan
		console.log(user)
				
		if (!idProcess) 
		return;
		
		let url = `${this.url}/${this.modelName}/setapproved/${idProcess}/${user}`;
		
		return this.http.put(url, null);
	}
	
	setAsRejected(idProcess: number, note: string) {//Rechasar Flujos Revisado por Nelson
		let user = this._authService.currentUserValue['Usuario']; //Variable nueva flujo Johan

		if (!idProcess || !note) 
			return;

		let url = `${this.url}/${this.modelName}/setrejected/${idProcess}/${user}`;

		return this.http.put(url, { IDPROCESO: idProcess, NOTA: note });
	}

	/**
	 * Actualiza el estado de de los procesos especificados con el valor indicado
	 * @param ids Ids de los procesos a actualizar
	 * @param value Nuevo estado
	 */
	updateState(ids: string, value: string) {
		if (!ids || !value) 
			return;

		let url = `${this.url}/${this.modelName}/updatestate/${ids}/${value}`;

		return this.http.put(url, null);
	}

	setArchived(ids: string, note: string) {
		if (!ids) 
			return;

		let url = `${this.url}/${this.modelName}/setarchived/${ids}?note=${note}`;

		return this.http.put(url, null);
	}


	getProcessFlow(processName: string) {
		let url = `${this.url}/${this.modelName}/flow/${processName}`;

		return this.http.get(url);
	}

	// Obtiene la cuenta de procesos pendientes del usuario actual
	getPendingProcessesCount(username: string) {
		this.getCountByUserAndName(null, username, 'pendientes').subscribe((count: number) => {
			this.pendingCountSubject.next(count);
		});

		return this.pendingCount;
	}

	refreshCounts() {
		this.getPendingProcessesCount(this._authService.currentUserValue['Usuario']);
	}

	getUserRoleInProcess(process: any, username: string) {
		let role = '';

		if (process) {
			Object.keys(process).filter(k => ['SOLICITANTE', 'APROBADOR1', 'APROBADOR2', 'APROBADOR3', 'APROBADOR4', 'APROBADOR5', 'APROBADOR6'].includes(k)).forEach(k => {
				if (process[k] === username)
					role = k;
			});
		}

		return role;
	}

	sendToApproval(idProcess: number) {
		if (!idProcess) 
			return;

		let url = `${this.url}/${this.modelName}/sendtoapproval/${idProcess}`;

		return this.http.put(url, null);
	}

	getProcessProcedureState(idProcess): Observable<string> {
		let url = `${this.url}/${this.modelName}/procedure-state/${idProcess}`;

		return this.http.get<string>(url);
	}

	restart(idProcess: number) {
		if (!idProcess) 
			return;

		let url = `${this.url}/${this.modelName}/restart/${idProcess}`;

		return this.http.put(url, null);
	}
}
