import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, Observable, BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';

import { BaseService } from './base.service';
import { APP_CONFIG } from 'app/config';
import { Module } from '../core/models/module.model';
import { MenuOption } from '../core/models/menu-option.model';
import { UserModuleOption } from '../core/models/user-module-option.model';
import { map } from 'rxjs/operators';
import { Notification } from '../core/models/notification.interface';
import * as moment from 'moment';

@Injectable({
	providedIn: 'root'
})
export class NotificationService extends BaseService {

	model: any;

    modelName = 'notification';
    
    private notificationsSubject: BehaviorSubject<any>;
    private activeNotificationsCountSubject: BehaviorSubject<any>;

    public notifications: Observable<any>;
    public activeNotificationsCount: Observable<number>;


	constructor(
		private _http: HttpClient
	) { 
        super(_http);
        
        this.notificationsSubject = new BehaviorSubject<any>([]);
        this.notifications = this.notificationsSubject.asObservable();
        this.activeNotificationsCountSubject = new BehaviorSubject<number>(0);
        this.activeNotificationsCount = this.activeNotificationsCountSubject.asObservable();

        this.initialize();
    }

    public get notificationsValue(): Notification[] {
        return this.notificationsSubject.value;
    }

    public get activeNotificationsCountValue(): number {
        return this.activeNotificationsCountSubject.value;
    }

    async initialize(user: any = null) {
        
        await this.loadPreviousNotifications(user);
        this._listenActiveNotificationsCount();
        // Refresca la lista de notificaciones cada 60 segundos
        // this.refreshInterval = window.setInterval(async () => {
        //     await this.loadPreviousNotifications(user);
        // }, this.refreshIntervalTime);
    }

    async loadPreviousNotifications(user: any = null) {
        if (user) {
            const url = `${this.url}/${this.modelName}/processes/${user.Usuario}`;
            const data = await this.http.get<any[]>(url).toPromise();
            const notifications = data.map(n => {
                const notif: Notification = {
                    id: n['IDNOTIF'],
                    date: n['FECHA'],
                    idProcess: n['IDPROCESO'],
                    process: n['ASUNTO'],
                    text: '',
                    processState: n['ESTADO_PROCESO']
                };

                switch (n.ESTADO_PROCESO) {
                    case 'EN_ERROR':
                        notif.text += `<p style="margin: 0 0 9px 0;"><span class="text-danger">Error en el proceso</span></p>`;
                        break;

                    case 'EN_PROCESO':
                        notif.text += `<p style="margin: 0 0 9px 0;"><span class="text-info">Pendiente por aprobación</span></p>`;
                        break;
                    default:
                        break;
                }

                const timeAgo = moment(n['FECHA']).fromNow();

                if (n.SOLICITANTE)
                    notif.text += `<p style="margin: 0 0 9px 0;"><small class="text-muted">Por: ${n['SOLICITANTE']} ${timeAgo}</small></p>`;
                else
                    notif.text += `${n['TEXTO']} ${timeAgo}`;

                return notif;
            });

            this.notificationsSubject.next(notifications);
        }
        else
            this.notificationsSubject.next([]);
    }

    pushNotification(notification: any) {
        let current = this.notificationsValue;
        current.push(notification);
        this.notificationsSubject.next(current);
    }

    private _listenActiveNotificationsCount() {
        this.notifications.subscribe(() => {
            // this.activeNotificationsCountSubject.next(this.notificationsValue.filter(n => n.state === 1).length);
            this.activeNotificationsCountSubject.next(this.notificationsValue.length);
        });
    }

    unsubscribeFromNotifications() {
        this.initialize(null);
    }
}
