import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { APP_CONFIG } from 'app/config';
import { isObject } from 'util';
import { GetDatos, InsertRespon, ResultDtx } from 'app/core/models/get-datos';

@Injectable({
	providedIn: 'root'
})
export abstract class BaseService {
	url: string;
	websiteUrl: string;
	modelName: string;
	model: any;

	constructor(protected http: HttpClient) {
		this.url = APP_CONFIG.api_url;
		this.websiteUrl = APP_CONFIG.website_url || '';
	}

	protected mapListToModelList(list: Array<any>) {
		if (!list)
			return [];
		else if (list.length !== undefined) {
			list.forEach((item, index) => {
				if (isObject(item))
					list[index] = this.mapModel(item);
			});
		}
		return list;
	}

	mapModel(model: any) {
		return this.model(model);
	}

	findById<T>(id: any, options: RequestOptions = {}) {
		let url = `${this.url}/${this.modelName}/${id}`;

		
		if (options['prefix'])
		url = `${this.url}/${this.modelName}/${options['prefix']}/${id}`;
		
		if (options['param'])
		url += `/${options['param']}`;
		
		console.log('urlForJohan', url);
		
		return this.http.get<T>(url).pipe(
			map(res => res ? (typeof res === 'object' ? this.mapModel(res) : res) : null)
		);
	}

	find(options: RequestOptions = {}) {
		let url = `${this.url}/${this.modelName}`;

		if (options['param'])
			url += `/${options['param']}`;

		if (options['all'])
			url += "/all";

		if (options['search'])
			url += `?search=${options['search']}`;

		let result = options['dynamic'] ? this.http.get<any[]>(url) : this.http.get(url).pipe(
			map((res: any[]) => this.mapListToModelList(res))
		);

		if (options['sortBy']) {
			result = result.pipe(
				map((res: any[]) => this.sortListBy(res, options['sortBy']))
			);
		}

		return result;
	}

	create(model: any, options: RequestOptions = {}) {
		let url = `${this.url}/${this.modelName}`;

		if (options['param'])
			url += `/${options['param']}`;
			
		let payload = this.changeBooleansToBits(this.removeVirtualProperties(model));
			
		// this.http.put(url, JSON.stringify(model))
		return this.http.post(url, payload).pipe(
			map(res => this.mapModel(res || model))
		);
	}

	update(model: any, id: any, options: RequestOptions = {}) {
		let url = `${this.url}/${this.modelName}`;

		if (options['param'])
			url += `/${options['param']}`;

		url += `/${id}`;

		let payload = this.changeBooleansToBits(this.removeVirtualProperties(model));
			
		// this.http.put(url, JSON.stringify(model))
		return this.http.put(url, payload).pipe(
			map(res => this.mapModel(res || model))
		);
	}

	updateMany(models: any[], options: RequestOptions = {}) {
		let url = `${this.url}/${this.modelName}`;
		let payload = models.map(m => this.removeVirtualProperties(m));

		if (options['param'])
			url += `/${options['param']}`;
			
		// this.http.put(url, JSON.stringify(model))
		return this.http.put(url, payload).pipe(
			map(res => this.mapModel(res || models))
		);
	}

	delete(id: any) {
		let url = `${this.url}/${this.modelName}/${id}`;
			
		// this.http.put(url, JSON.stringify(model))
		return this.http.delete(url);
	}

	getEntityInfo(options: RequestOptions = {}) {
		let url = `${this.url}/${this.modelName}/entityinfo`;

		if (options['param'])
			url += `/${options['param']}`;

		return this.http.get<any>(url);
	}

	generateReport(type: string, entity: string, query: string = '', url: string = '', orientation: string = '', download: boolean = true) {
		let finalQuery = ''; 
		let finalUrl = ''; 
		console.log('url :>> ', url);

		if (query)
			finalQuery = window.btoa(unescape(encodeURIComponent( query.replace(/\n/g, ' ').replace(/\t/g, '').trim() )));

		if (url)
			finalUrl = window.btoa(unescape(encodeURIComponent( url )));

		console.log('url :>> ', url);
		let reportUrl = `${this.url}/reports/${type}/${entity}?query=${finalQuery}&url=${finalUrl}`;

		if (orientation)
			reportUrl += `&orientation=${orientation}`;

		reportUrl += `&download=${download}`;
			
        window.open(reportUrl, '_blank');
        // return this._http.get(`${this.url}/${this.modelName}/report`);
    }

	/**
	 * Elimina propiedades virtuales del objeto especificado
	 * Una propiedad definida como virtual se reconoce con el símbolo '$' al inicio su nombre
	 */
	private removeVirtualProperties(obj: object) {
		let finalObject = Object.assign({}, obj);
		
		Object.keys(finalObject).forEach(key => {
			if (key.startsWith("$"))
				delete finalObject[key];
		});

		return finalObject;
	}

	private changeBooleansToBits(obj: object) {
		let finalObject = Object.assign({}, obj);
		
		Object.keys(finalObject).forEach(key => {
			if (finalObject[key] === true)
				finalObject[key] = 1;
			else if (finalObject[key] === false)
				finalObject[key] = 0;
		});

		return finalObject;
	}

	private sortListBy(list: any[], prop: string) {
		list.sort((a, b) => {
			if (a && a.hasOwnProperty(prop) && b && b.hasOwnProperty(prop)) {
				return a[prop].localeCompare(b[prop]);
			}
			else return 1;
		});

		return list;
	}


	tempUser(){
		let urlTemp = `${APP_CONFIG.url_Dx}/grid2`;
		let obj = {
			"id": "asivamos_ontime_usuarios",
			"take": 10,
			"skip": 0,
			"filter": []
		}

			// this.http.put(url, JSON.stringify(model))
			return this.http.post(urlTemp, obj);
	}

	getDatos(obj:GetDatos){
		let url = `${APP_CONFIG.url_Dx}/grid2`;
		return this.http.post<ResultDtx>(url, obj).pipe(
		  map( resp => resp.rows)
		);
	  }
	
	  newInsert(idCrud:string, obj:object){
		let url = `${APP_CONFIG.url_Dx}/crud2/${idCrud}/`;
		return this.http.post<InsertRespon[]>(url, obj);
	  }
	
	  updateDatos(idCrud:string, obj:object ){
		let url = `${APP_CONFIG.url_Dx}/crud2/${idCrud}`;
		return this.http.put(url, obj);
	  }
	
	  deleteItem(idCrud:string, idItem:number){
		let url = `${APP_CONFIG.url_Dx}/crud2/${idCrud}/${idItem}`;
		return this.http.delete(url);
	  }


}

interface RequestOptions {
	param?: any;
	prefix?: any;
	all?: boolean;
	search?: string;	
	dynamic?: boolean;
	sortBy?: any;	
}