import * as moment from 'moment';

export const Utils = {

    stringToSlug (str) {
        str = str.replace(/^\s+|\s+$/g, ''); // trim
        str = str.toLowerCase();
      
        // remove accents, swap ñ for n, etc
        const from = "àáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
        const to   = "aaaaaeeeeiiiioooouuuunc------";
    
        for (let i = 0, l = from.length ; i < l ; i++) {
            str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
        }
    
        str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
            .replace(/\s+/g, '-') // collapse whitespace and replace by -
            .replace(/-+/g, '-'); // collapse dashes
    
        return str;
    },

    removeSpecialCharacters (str): string {
        // remove accents, swap ñ for n, etc
        const from = "àáãäâèéëêìíïîòóöôùúüûñç";
        const to   = "aaaaaeeeeiiiioooouuuunc";
    

        for (let i = 0, l = from.length ; i < l ; i++) {
            str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
        }
    
        return str;
    },

    addressString(str: string) {
        return str.replace(/[^0-9a-zñáéíóúü\s|#|-]/gi, '');
    },

    dateToInt (date: any) {
        if (!date || !moment(date).isValid())
            return 0;
        else
            return parseInt(moment(date).format("YYYY-MM-DD").toString().replace(/-/g, ''));
    },

    dateToDB2Date(date: any) {
        if (!date || !moment(date).isValid())
            return date;
        else
            return `${moment(date).format("YYYY-MM-DD-HH.mm.ss").toString()}`;
    },

    checkDigitoNit (nit1) { 
        let vpri, x, y, z, i, dv1;

        if (nit1.length == 4) return true;

        if (isNaN(nit1)) {
            console.info('El valor digitado no es un numero valido');
            return false;
        }
        else {
            vpri = new Array(16);
            x = 0; y = 0;
            z = nit1.length - 1;
            vpri[1] = 3; vpri[2] = 7; vpri[3] = 13; vpri[4] = 17; vpri[5] = 19;
            vpri[6] = 23; vpri[7] = 29; vpri[8] = 37; vpri[9] = 41; vpri[10] = 43;
            vpri[11] = 47; vpri[12] = 53; vpri[13] = 59; vpri[14] = 67; vpri[15] = 71;

            for (i = 0; i < z; i++) {
                y = (nit1.substr(i, 1));
                x += (y * vpri[z - i]);
            }
            y = x % 11;
            if (y > 1) {
                dv1 = 11 - y;
            } else {
                dv1 = y;
            }
            y = (nit1.substr(z, 1));
            return y == dv1;
        }
    },

    getUserAgent() {
        return window.navigator.userAgent;
    },

    isMobile() {
        return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
    },

    getWindowDimensions() {
        return [window.outerWidth, window.outerHeight];
    },

    getURLQueryParams(url: string = null) {
        let result = {};
        let query = (url || window.location.search).split('?');

        if (query.length > 1) {
            query[1].split('&').forEach(p => {
                let keyVal = p.split('=');
                result[keyVal[0]] = decodeURIComponent(keyVal[1]);
            });
        }

        return result;
    },

    wait(miliseconds: number) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(null);
            }, miliseconds);
        });
    },

    checkChangedValues(objA, objB) {
		let props = [];

		Object.keys(objA).forEach(k => {
			if (objA[k] !== objB[k])
				props.push(k);
		});
		
		return props;
    },
    
    capitalize(str: string) {
        return str.toLowerCase().split(' ').map(s => {
            return s.charAt(0).toUpperCase() + s.slice(1);
        }).join(' ');
    },

    currencyFormatter(value, decimals, sign) {
		const sansDec = Number(value);
		const spl = sansDec.toString().split('.');
		const n = spl.length > 1 ? spl[1].length : 0;
		const x = 3;
		const re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
		// tslint:disable-next-line:no-bitwise
		const number = sansDec.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,');
		return `${sign}${number}`;
	},

    isHtml(str: string) {
        if (!str)
            return false;
        else
            return /<\/?[a-z][\s\S]*>/i.test(str);
    }
};