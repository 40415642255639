import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NotificationService } from '../../providers/notification.service';

@Component({
    selector: "app-notifications-count",
    templateUrl: "./notifications-count.component.html",
	styleUrls: ["./notifications-count.component.scss"],
})
export class NotificationsCountComponent implements OnInit, OnDestroy {

	count: number;
	changing: boolean;

	private _destroyed$ = new Subject(); // Subject para eliminar las subscripciones de forma masiva

    constructor(
		private _notificationService: NotificationService,
		public changeDetection: ChangeDetectorRef
	) {

	}

    ngOnInit() {
		this._notificationService.activeNotificationsCount.pipe(takeUntil(this._destroyed$)).subscribe(anc => {
			this.count = anc;
			this.notifyChange();
		});
	}

	notifyChange() {
		this.changing = true;

		setTimeout(() => {
			this.changing = false;
			this.changeDetection.detectChanges();
		});
	}

	ngOnDestroy() {
		this._destroyed$.next();
    	this._destroyed$.complete();
	}
}
